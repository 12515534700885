import React from 'react'

export default function Logo({ fill, stroke, size, className }: {className?: string, size?: number, stroke?: string, fill?: string }) {

  // @ts-ignore
  const fillColor = fill ?? (window.nightmode ? '#000000' : '#ffffff')
  // @ts-ignore
  const strokeColor = stroke ?? (window.nightmode ? '#ffffff' : '#000000')
  size = size || 638
  return (
    <svg className={`logo imageSpin ${className || ''}`} width={size} height={size} viewBox="0 0 638 638" xmlns="http://www.w3.org/2000/svg">
      <path
        className="__svgOutter"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="14"
        d="M319 14L340.298 48.3868L366.713 17.7551L382.369 55.0502L413.25 28.9278L422.879 68.2129L457.467 47.243L460.832 87.5508L498.275 72.2498L495.293 112.588L534.668 103.332L525.412 142.707L565.75 139.725L550.449 177.168L590.757 180.533L569.787 215.121L609.072 224.75L582.95 255.631L620.245 271.287L589.613 297.702L624 319L589.613 340.298L620.245 366.713L582.95 382.369L609.072 413.25L569.787 422.879L590.757 457.467L550.449 460.832L565.75 498.275L525.412 495.293L534.668 534.668L495.293 525.412L498.275 565.75L460.832 550.449L457.467 590.757L422.879 569.787L413.25 609.072L382.369 582.95L366.713 620.245L340.298 589.613L319 624L297.702 589.613L271.287 620.245L255.631 582.95L224.75 609.072L215.121 569.787L180.533 590.757L177.168 550.449L139.725 565.75L142.707 525.412L103.332 534.668L112.588 495.293L72.2498 498.275L87.5508 460.832L47.243 457.467L68.2129 422.879L28.9278 413.25L55.0502 382.369L17.7551 366.713L48.3868 340.298L14 319L48.3868 297.702L17.7551 271.287L55.0502 255.631L28.9278 224.75L68.2129 215.121L47.243 180.533L87.5508 177.168L72.2498 139.725L112.588 142.707L103.332 103.332L142.707 112.588L139.725 72.2498L177.168 87.5508L180.533 47.243L215.121 68.2129L224.75 28.9278L255.631 55.0502L271.287 17.7551L297.702 48.3868L319 14Z"
      />
      <path
        className="__svgMiddle"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="12"
        d="M319 84L335.41 110.495L355.762 86.8932L367.825 115.629L391.619 95.5017L399.038 125.771L425.688 109.613L428.281 140.67L457.13 128.881L454.832 159.961L485.17 152.83L478.039 183.168L509.119 180.87L497.33 209.719L528.387 212.312L512.229 238.962L542.498 246.381L522.371 270.175L551.107 282.238L527.505 302.59L554 319L527.505 335.41L551.107 355.762L522.371 367.825L542.498 391.619L512.229 399.038L528.387 425.688L497.33 428.281L509.119 457.13L478.039 454.832L485.17 485.17L454.832 478.039L457.13 509.119L428.281 497.33L425.688 528.387L399.038 512.229L391.619 542.498L367.825 522.371L355.762 551.107L335.41 527.505L319 554L302.59 527.505L282.238 551.107L270.175 522.371L246.381 542.498L238.962 512.229L212.312 528.387L209.719 497.33L180.87 509.119L183.168 478.039L152.83 485.17L159.961 454.832L128.881 457.13L140.67 428.281L109.613 425.688L125.771 399.038L95.5017 391.619L115.629 367.825L86.8932 355.762L110.495 335.41L84 319L110.495 302.59L86.8932 282.238L115.629 270.175L95.5017 246.381L125.771 238.962L109.613 212.312L140.67 209.719L128.881 180.87L159.961 183.168L152.83 152.83L183.168 159.961L180.87 128.881L209.719 140.67L212.312 109.613L238.962 125.771L246.381 95.5017L270.175 115.629L282.238 86.8932L302.59 110.495L319 84Z"
      />
      <path
        className="__svgInner"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="10"
        d="M319 149L330.871 168.166L345.594 151.093L354.32 171.88L371.533 157.32L376.9 179.217L396.178 167.529L398.054 189.996L418.923 181.467L417.261 203.951L439.208 198.792L434.049 220.739L456.533 219.077L448.004 239.946L470.471 241.822L458.783 261.1L480.68 266.467L466.12 283.68L486.907 292.406L469.834 307.129L489 319L469.834 330.871L486.907 345.594L466.12 354.32L480.68 371.533L458.783 376.9L470.471 396.178L448.004 398.054L456.533 418.923L434.049 417.261L439.208 439.208L417.261 434.049L418.923 456.533L398.054 448.004L396.178 470.471L376.9 458.783L371.533 480.68L354.32 466.12L345.594 486.907L330.871 469.834L319 489L307.129 469.834L292.406 486.907L283.68 466.12L266.467 480.68L261.1 458.783L241.822 470.471L239.946 448.004L219.077 456.533L220.739 434.049L198.792 439.208L203.951 417.261L181.467 418.923L189.996 398.054L167.529 396.178L179.217 376.9L157.32 371.533L171.88 354.32L151.093 345.594L168.166 330.871L149 319L168.166 307.129L151.093 292.406L171.88 283.68L157.32 266.467L179.217 261.1L167.529 241.822L189.996 239.946L181.467 219.077L203.951 220.739L198.792 198.792L220.739 203.951L219.077 181.467L239.946 189.996L241.822 167.529L261.1 179.217L266.467 157.32L283.68 171.88L292.406 151.093L307.129 168.166L319 149Z"
      />
      <path
        className="__svgRM"
        fill={strokeColor || 'none'}
        d="M260.068 274.642C270.14 274.642 277.863 276.884 283.237 281.37C288.612 285.813 291.299 292.14 291.299 300.35C291.299 304.835 290.05 308.813 287.554 312.283C285.057 315.753 281.481 318.483 276.826 320.472C281.862 322.122 285.48 324.809 287.681 328.533C289.924 332.257 291.045 336.849 291.045 342.308V349.734C291.045 352.57 291.405 354.664 292.124 356.019C292.843 357.373 294.028 358.177 295.679 358.431L298.726 358.812V367H291.997C287.131 367 283.66 365.307 281.587 361.922C279.556 358.536 278.54 354.432 278.54 349.607V342.435C278.54 337.399 277.08 333.357 274.16 330.311C271.24 327.264 267.326 325.74 262.417 325.74H242.358V357.225L252.705 359.002V367H219.507V359.002L229.854 357.225V284.417L219.507 282.64V274.642H229.854H260.068ZM242.358 315.901H258.799C265.781 315.901 270.859 314.568 274.033 311.902C277.207 309.236 278.794 305.343 278.794 300.223C278.794 295.356 277.271 291.505 274.224 288.67C271.177 285.835 266.458 284.417 260.068 284.417H242.358V315.901ZM397.812 292.986L397.495 292.923L367.598 367H359.282L329.385 292.986L329.067 293.05L330.337 330.438V357.225L340.684 359.002V367H307.485V359.002L317.832 357.225V284.417L307.485 282.64V274.578H317.832H333.828L363.281 349.671H363.662L393.052 274.578H419.458V282.64L409.111 284.417V357.225L419.458 359.002V367H386.26V359.002L396.606 357.225V330.438L397.812 292.986Z"
      />
    </svg>
  )
}
